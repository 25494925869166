.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Preencher toda a altura da tela */
    padding: 20px;
    background-color: #f9f9f9; /* Cor de fundo leve */
}

.login-image {
    width: 80%;
    height: 70px;
    margin-bottom: 20px; /* Espaçamento entre as imagens */
    cursor: pointer; /* Indica que a imagem é clicável */
    object-fit: contain;
}

.login-input {
    width: 80%;
    height: 50px;
    margin-bottom: 10px; /* Espaçamento entre os inputs */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-button {
    width: 80%;
    height: 50px;
    background-color: #00BFA5; /* Cor de fundo do botão */
    color: white; /* Cor do texto */
    font-weight: bold; /* Texto em negrito */
    text-transform: uppercase; /* Texto em caixa alta */
    border: none;
    border-radius: 5px;
    font-size: 16px; 
    cursor: pointer; /* Indica que o botão é clicável */
    margin-bottom: 10px; /* Espaçamento abaixo do botão */
}

.download-text {
    text-align: center; /* Centraliza o texto */
    margin-top: 10px; /* Espaçamento acima do texto */
}

.popup-message {
    color: red; /* Cor do texto da mensagem de erro */
    margin-top: 10px; /* Espaçamento acima da mensagem de erro */
}

.login-container .popup-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.login-container .popup-content-message {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.login-container .close-popup-message {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}