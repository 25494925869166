.nailshape-content {
    height: calc(100vh - 50px);
    width: 400px;
    margin: 0 auto;
}

.nailshape-content .nailshape-header {
    display: flex;
    justify-content: flex-start; /* Posicionar o ícone X no canto superior esquerdo */
    margin-top: 10px;
    margin-bottom: 10px;
}

.nailshape-content .nailshape-close-icon {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #00BFA5;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.nailshape-content .nailshape-selected {
    text-align: center;
}

.nailshape-content .nailshape-name {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
}

.nailshape-content .nailshape-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.nailshape-content .nailshape-option {
    display: flex;
    align-items: center; /* Centraliza a imagem e o texto verticalmente */
}

.nailshape-content .nailshape-option input {
    margin-right: 10px;
}

.nailshape-content .nailshape-option label {
    display: flex;
    align-items: center; /* Centraliza o texto e a imagem do label */
}

.nailshape-content .nailshape-option img {
    margin-right: 10px;
    width: 75px;
    height: 75px;
}

.nailshape-content .nailshape-option span {
    line-height: 75px; /* Alinha o texto verticalmente com a imagem */
}

.nailshape-content .update-button {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 50px; /* Adiciona espaço abaixo do botão */
}

.nailshape-content .update-button:hover {
    background-color: #008f7a;
}

.nailshape-content .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.nailshape-content .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.nailshape-content .close-popup {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}