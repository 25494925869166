.podopathologies-container {
  width: 400px;
  margin: 0 auto;
  height: calc(100vh - 50px);
}

.podopathologies-container .radio-group {
  margin-bottom: 20px;
}

.podopathologies-container .radio-options {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.podopathologies-container .radio-options label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.podopathologies-container .radio-options input {
  cursor: pointer;
}

.podopathologies-container .save-button {
  background-color: #00BFA5;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 50px;
}

.podopathologies-container .save-button:hover {
  background-color: #008f7a;
}

.podopathologies-container .divider {
  border-bottom: 1px solid #ccc; /* Cor e estilo do divisor */
  margin: 20px 0; /* Espaçamento acima e abaixo */
}

.podopathologies-container .podopathologies-header {
    display: flex;
    justify-content: flex-start; /* Posicionar o ícone X no canto superior esquerdo */
    margin-top: 10px;
    margin-bottom: 10px;
}

.podopathologies-container .podopathologies-close-icon {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #00BFA5;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.podopathologies-container .popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Fica acima de outros elementos */
}

.podopathologies-container .popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.podopathologies-container .close-popup {
  background-color: #00BFA5;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}