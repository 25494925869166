.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fundo branco para a tela de carregamento */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white; /* Cor de fundo branca */
}

/* Barra de carregamento */
.loading-bar {
  width: 0;
  height: 10px;
  background-color: #00BFA5; /* Cor verde */
  border-radius: 5px;
  animation: loading-grow 2s ease-in-out forwards; /* Animação de crescimento */
}

/* Animação que faz a barra crescer */
@keyframes loading-grow {
  0% {
    width: 0;
  }
  100% {
    width: 100px; /* Tamanho final da barra */
  }
}
