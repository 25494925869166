.notes-container {
    padding: 20px;
    height: calc(100vh - 50px);
}

.notes-container .notes-header {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.notes-container .notes-add-btn {
    padding: 10px 20px;
    background-color: #00BFA5; /* Verde */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.notes-container .notes-close-icon {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #00BFA5;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    margin-right: 10px;
}

.notes-container .notes-grid {
    display: grid;
    grid-template-columns: repeat(5, 150px);
    gap: 16px;
    margin-top: 25px;
    padding-bottom: 25px;
}

.notes-container .notes-portrait {
    position: relative;
    width: 150px;
    height: 250px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.notes-container .notes-delete-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: red;
    font-size: 20px;
    cursor: pointer;
}

.notes-container .notes-content {
    padding: 16px;
    font-size: 14px;
    flex-grow: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.notes-container .notes-footer {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 8px;
}

.notes-container .notes-date {
    font-size: 12px;
}

.notes-container .notes-popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.notes-container .notes-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;
}

.notes-container .notes-popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
}

.notes-container .notes-popup-text {
    font-size: 14px;
    margin-top: 40px;
}

.notes-container .notes-popup-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.notes-container .notes-btn {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.notes-container .notes-btn-cancel {
    background-color: #00BFA5;
    color: white;
}

.notes-container .notes-btn-delete {
    background-color: red;
    color: white;
}

.notes-container .notes-btn-add {
    background-color: #00BFA5;
    color: white;
    width: 100%;
    margin-top: 16px;
}

.notes-container .notes-new-annotation,
.notes-container .notes-new-date {
    width: calc(100% - 16px);
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    resize: none;
}
