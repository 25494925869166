/* Estilos para o layout geral */
.hands-layout {
    height: calc(100vh - 50px);
    margin: 0 auto;
    display: flex;
    flex-direction: column; /* Alinhar elementos em coluna */
}

.hands-layout .hands-header {
    display: flex;
    justify-content: flex-start; /* Posicionar o ícone X no canto superior esquerdo */
    margin-top: 10px;
    margin-bottom: 10px;
}

.hands-layout .hands-close-icon {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #00BFA5;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.hands-layout .hands-close-icon:hover {
    background-color: #009688;
}

.hands-layout .tab-buttons {
    display: flex;
    justify-content: space-between; /* Distribuir igualmente os botões */
    margin-bottom: 20px;
}

.hands-layout .tab-buttons button {
    flex: 1;
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 200px;
}

.hands-layout .tab-buttons button:hover {
    background-color: #009688;
}

.hands-layout .tab-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
}
