.patientLeftHand {
    height: auto;
    margin: 0 auto;
    text-align: center;
}

.patientLeftHand p {
    text-align: center;
    font-weight: bold;
    color: var(--thirdColor);
    font-size: 25px;
    margin-bottom: 20px;
}

.patientLeftHand .symptomList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
}

.patientLeftHand .symptomList li {
    width: calc(25% - 10px); /* Cada item ocupa 25% da largura menos margens */
    height: 50px; /* Aumenta a altura dos itens para melhor aparência */
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 5px; /* Adiciona margem entre os itens */
    text-align: center; /* Centraliza o texto dentro de cada item */
    border-radius: 5px; /* Adiciona bordas arredondadas aos itens */
    display: flex; /* Para permitir o alinhamento do conteúdo */
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
}

.patientLeftHand .imgAndDots {
    position: relative;
    width: 300px;
    margin: 20px auto; /* Espaço adicional entre a imagem e a lista de sintomas */
    height: 400px;
}

.patientLeftHand .imgAndDots img {
    width: 300px;
    height: 400px;
}

.patientLeftHand .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
}
