/* TermsOfService.css and PrivacyPolicy.css */
.top-bar-terms {
    background-color: #00BFA5;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.back-button-terms {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.app-title-terms {
    color: white;
    font-size: 24px;
    margin: 0;
}

.content-terms {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

h2 {
    margin-bottom: 20px;
    color: #00BFA5;
}

h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #00796B;
}

p {
    margin-bottom: 15px;
}

@media (max-width: 600px) {
    .content-terms {
        padding: 15px;
        font-size: 14px;
    }

    .app-title-terms {
        font-size: 20px;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 18px;
    }
}
