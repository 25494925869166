.patient-details-content {
  text-align: center;
}

.patient-detail-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.patient-detail-name {
  font-size: 24px;
  margin-bottom: 10px;
}

.patient-detail-phone {
  font-size: 18px;
  color: #777;
  margin-bottom: 50px; /* Adds 50px space before the buttons */
}

.patient-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient-section-button {
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: #00BFA5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
}

.patient-section-button:hover {
  background-color: #009688;
}

.patient-section-content {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

/* Estilos do modal */
.patient-details-content .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.patient-details-content .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.patient-details-content .modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.patient-details-content .modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.patient-details-content .modal-buttons button:first-child {
  background-color: gray;
  color: white;
}

.patient-details-content .modal-buttons button:last-child {
  background-color: #00BFA5;
  color: white;
}

.patient-details-content input {
  margin-top: 10px;
  padding: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.patient-details-content  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.patient-details-content  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.patient-details-content  .close-popup {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}
