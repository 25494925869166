.account-details-container-account {
    display: flex;
    justify-content: center;
    padding-top: 20px; /* Ajuste o espaço em relação ao topo */
    margin-bottom: 50px;
}
  
.account-details-content-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}
  
.account-photo-account {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}
  
.account-name-input-account {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}
  
.account-email-account {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}
  
.update-button-account {
    width: 100%;
    padding: 12px;
    background-color: #00BFA5; /* Cor principal */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}
  
.update-button-account:disabled {
    background-color: #64FFDA; /* Cor de detalhes */
    cursor: not-allowed;
}

.loading-container-account {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.progress-bar-account {
    width: 200px;
    height: 20px;
    border-radius: 10px;
    background-color: #f3f3f3;
    position: relative;
    overflow: hidden;
}
  
.progress-bar-account::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00BFA5; /* Cor principal verde */
    animation: loading-account 1.5s infinite;
}
  
@keyframes loading-account {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.update-email-section-account {
    margin-top: 20px;
    width: 100%;
}

.update-email-title-account {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 50px;
}

.update-email-input-account {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.email-update-button-account {
    width: 100%;
    padding: 12px;
    background-color: #00BFA5; /* Cor principal */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.email-update-button-account:disabled {
    background-color: #64FFDA; /* Cor de detalhes */
    cursor: not-allowed;
}

.delete-button-account {
    width: 100%;
    padding: 12px;
    background-color: red; /* Cor principal */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.delete-button-account:disabled {
    background-color: rgb(252, 72, 72); /* Cor de detalhes */
    cursor: not-allowed;
}

.account-details-container {
    display: flex;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center;
    height: 100vh;
}

.account-details-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Limita a largura do conteúdo */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.account-photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto 20px; /* Centraliza a imagem e adiciona margem inferior */
}

input[type="text"],
input[type="email"],
input[type="password"],
.update-button,
.email-update-button {
    width: 100%; /* Faz os inputs ocuparem toda a largura */
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.update-button,
.email-update-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.update-button:disabled,
.email-update-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.update-email-section {
    margin-top: 20px;
}

.update-email-section-account label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.account-details-container-account .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.account-details-container-account .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.account-details-container-account .close-popup {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}