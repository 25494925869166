.monitorings-container {
    padding: 20px;
    height: calc(100vh - 50px);
}
  
.monitorings-container .monitorings-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
  
.monitorings-container .monitorings-add-btn {
    padding: 10px 20px;
    background-color: #00BFA5; /* Verde */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.monitorings-container .monitorings-close-icon {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #00BFA5;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    margin-right: 10px;
}
  
.monitorings-container .monitorings-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(150px, 1fr));
    gap: 15px;
    margin-top: 25px;
    padding-bottom: 25px;
}
  
.monitorings-container .monitorings-portrait {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s;
}
  
.monitorings-container .monitorings-portrait:hover {
    transform: scale(1.02);
    cursor: pointer;
}
  
.monitorings-container .monitorings-delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 5px;
    transition: background-color 0.2s;
}
  
.monitorings-container .monitorings-delete-icon:hover {
    background-color: rgba(255, 0, 0, 0.7);
}
  
.monitorings-container .monitorings-footer {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 8px;
}
  
.monitorings-container .monitorings-date {
    font-weight: bold;
}
  
.monitorings-container .monitorings-popup-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.monitorings-container .monitorings-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%; /* Alterar de 90% para 100% para evitar o overflow */
    max-height: 80%; /* Limitar a altura máxima do popup */
    overflow-y: auto; /* Adicionando rolagem vertical */
    overflow-x: hidden; /* Impedir o overflow horizontal */
    box-sizing: border-box; /* Incluir padding e borda no cálculo da largura */
}
  
.monitorings-container .monitorings-popup-close {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
}
  
.monitorings-container .monitorings-popup-text {
    margin: 20px 0;
}
  
.monitorings-container .monitorings-popup-actions {
    display: flex;
    justify-content: space-between;
}
  
.monitorings-container .monitorings-btn {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.monitorings-container .monitorings-btn-cancel {
    background-color: #00BFA5;
    color: white;
}

.monitorings-container .monitorings-btn-delete {
    background-color: red;
    color: white;
}

.monitorings-container .monitorings-btn-add {
    background-color: #00BFA5;
    color: white;
    width: 100%;
    margin-top: 16px;
}

.monitorings-container .monitorings-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    max-height: 80%; /* Limitar a altura máxima do popup */
    overflow-y: auto; /* Adicionando rolagem vertical */
}

.monitorings-container .monitorings-new-data {
    width: 100%; /* Certificar que o textarea ocupa 100% da largura do popup */
    height: 100px;
    margin-top: 10px;
    box-sizing: border-box; /* Garantir que o padding não afete a largura */
}

.monitorings-container .monitorings-new-date {
    width: 100%; /* Certificar que o campo de data ocupa 100% da largura */
    margin-top: 10px;
    box-sizing: border-box; /* Garantir que o padding não afete a largura */
}

.monitorings-container input[type="file"] {
    width: 100%; /* Certificar que o campo de upload de imagem ocupa 100% da largura */
    margin-top: 10px;
    box-sizing: border-box; /* Garantir que o padding não afete a largura */
}

.monitorings-container .monitorings-popup-text {
    margin: 20px 0;
    max-height: 80%; /* Para evitar que o texto ultrapasse a altura máxima */
    overflow-y: auto; /* Tornar o texto scrollable */
}


.monitorings-container .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.monitorings-container .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.monitorings-container .close-popup {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}