.profile {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os itens horizontalmente */
    height: calc(100vh - 50px);
    margin-top: 25px;
}

.profile .profile-header {
    display: flex;
    justify-content: flex-start; /* Posicionar o ícone X no canto superior esquerdo */
    margin-top: 10px;
    margin-bottom: 10px;
}

.profile .profile-close-icon {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: #00BFA5;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.profile .profile-container {
    width: 100%;
    max-width: 400px; /* Define a largura máxima */
}

.profile .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.profile .image-upload {
    margin-bottom: 20px;
}

.profile label {
    font-weight: bold;
    margin-bottom: 5px;
}

.profile input[type="file"],
.profile input[type="text"],
.profile input[type="email"],
.profile input[type="password"],
.profile input[type="date"] {
    width: 100%; /* Certifica que todos os inputs tenham a mesma largura */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Inclui padding e borda na largura total */
}

.profile .gender-container {
    display: flex;
    align-items: center; /* Alinha os itens verticalmente ao centro */
    justify-content: flex-start; /* Alinha os itens à esquerda */
    margin-bottom: 20px; /* Espaçamento adicional abaixo dos botões de rádio */
}

.profile .gender-container input {
    margin-right: 5px; /* Espaço entre os botões de rádio e os textos */
    margin-top: 15px;
}

.profile .gender-container label {
    margin-right: 20px; /* Espaço entre os textos dos rótulos */
    margin-top: 15px;
}

.profile .save-button {
    background-color: #00BFA5;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 25px;
}

.profile .loading-bar {
    height: 4px;
    background-color: green;
    width: 100%;
    position: absolute;
    top: 60px; /* Ajusta a posição da barra de loading */
    left: 0;
}

.profile .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.profile .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.profile .close-popup {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.loading-container-account {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.progress-bar-account {
    width: 200px;
    height: 20px;
    border-radius: 10px;
    background-color: #f3f3f3;
    position: relative;
    overflow: hidden;
}
  
.progress-bar-account::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00BFA5; /* Cor principal verde */
    animation: loading-account 1.5s infinite;
}
  
@keyframes loading-account {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.profile .image-container {
    position: relative; /* Permite que o botão seja posicionado em relação à imagem */
}

.profile .close-button {
    position: absolute;
    top: 5px; /* Ajuste conforme necessário */
    right: 5px; /* Ajuste conforme necessário */
    background: none; /* Remove fundo */
    border: none; /* Remove borda */
    color: black; /* Cor do texto do botão */
    font-size: 20px; /* Tamanho do texto */
    cursor: pointer; /* Aponta para o cursor */
    z-index: 1; /* Garante que o botão fique acima da imagem */
}