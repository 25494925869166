
/* src/components/LoginPage.css */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
  
/* src/components/LoginPage.css */
.login-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.login-page .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
  background-color: #00BFA5; /* Cor principal */
  color: white;
  height: 40px;
}

.login-page .navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
}

.login-page .navbar-logo:hover {
  cursor: pointer;
}

.login-page .login-form {
  display: flex;
  align-items: center; /* Centraliza verticalmente */
  gap: 10px;
}

.login-page .login-form input {
  padding: 5px;
  border: none;
  border-radius: 3px;
  height: 25px;
  font-size: 14px; /* Ajuste o tamanho da fonte, se necessário */
}

.login-page .login-form button {
  background-color: white;
  color: #00BFA5;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  height: 30px; /* Mantenha a mesma altura que os inputs */
  display: flex; 
  align-items: center; 
  justify-content: center; 
  font-size: 12px; 
  flex: 1; /* Faz os botões crescerem para preencher o espaço disponível */
  white-space: nowrap; /* Impede a quebra de linha */
}

.login-page .login-form button:hover {
  background-color: #e0f7fa;
}

/* Estilos adicionais para o seu carrossel */
.login-page .carousel {
  width: 100%;
  height: auto; /* Defina uma altura fixa */
  margin: 0 auto; /* Centraliza horizontalmente */
  overflow: hidden; /* Oculta qualquer conteúdo que exceda o contêiner */
}

.login-page .carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta as imagens para cobrir todo o espaço sem distorção */
}

/* Para garantir que o carrossel não afete o layout */
.login-page .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden; /* Garante que o carrossel não cause rolagem extra */
}

/* src/components/LoginPage.css */
.login-page .register-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-page .popup-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 500px;
  position: relative;
}

.login-page .close-popup {
  position: absolute;
  top: 20px; /* Ajuste a posição do topo */
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transform: translateY(-50%); /* Centraliza verticalmente em relação ao primeiro campo */
}

.login-page .register-form {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Increased spacing between elements */
}

.login-page .register-form input[type="text"],
.login-page .register-form input[type="email"],
.login-page .register-form input[type="password"] {
  padding: 12px; /* Increased height of the input fields */
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
}

.login-page .register-form label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.login-page .register-form button {
  padding: 12px;
  background-color: #00BFA5; /* Green color for the button */
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-page .register-form button:hover {
  background-color: #64FFDA; /* Slightly darker green on hover */
}

/* Adjust spacing between checkbox and button */
.login-page .register-form .checkbox-container {
  margin-top: 10px;
}

.login-page .loading-container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.login-page .progress-bar-login {
    width: 200px;
    height: 20px;
    border-radius: 10px;
    background-color: #f3f3f3;
    position: relative;
    overflow: hidden;
}
  
.login-page .progress-bar-login::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00BFA5; /* Cor principal verde */
    animation: loading-account 1.5s infinite;
}

.login-page .popup-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.login-page .popup-content-message {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.login-page .close-popup-message {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}