.premium-container {
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Posiciona o box no topo */
    margin-top: 30px; /* Espaçamento do topo */
    background-color: #f5f5f5; /* Fundo cinza claro */
}

.premium-box {
    background-color: #fff; /* Fundo branco */
    width: 400px; /* Largura do box */
    padding: 20px; /* Espaçamento interno */
    border-radius: 10px; /* Bordas arredondadas */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra para dar destaque */
}

.premium-box p {
    font-size: 16px; /* Tamanho da fonte */
    color: #333; /* Cor do texto */
    margin: 10px 0; /* Margem entre os parágrafos */
}

.divider {
    border: none;
    height: 1px;
    background-color: #ccc; /* Cor do divisor */
    margin: 25px 0; /* Margem acima e abaixo */
}

.loading-container-premium {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
    
.progress-bar-premium {
    width: 200px;
    height: 20px;
    border-radius: 10px;
    background-color: #f3f3f3;
    position: relative;
    overflow: hidden;
}
    
.progress-bar-premium::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00BFA5; /* Cor principal verde */
    animation: loading-account 1.5s infinite;
}

.premium-container h4 {
    width: 100%;
    text-align: center;
}

.premium-container .btnPayment {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    background-color: #00BFA5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 20px;
}