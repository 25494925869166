.patientlist-container {
    display: flex;
    height: calc(100vh - 50px);
    width: 100%;
    background-color: #F8F8F8;
    box-sizing: border-box;
  }
  
  .patientlist-container .patientlist {
    width: 20%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-right: 1px solid #ccc;
  }
  
  .patientlist-container .details-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow-y: auto;
  }
  
  .patientlist-container .patientlist-header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .patientlist-container .search-patient-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .patientlist-container .add-patient-button {
    margin-left: 10px;
    padding: 10px;
    background-color: #00BFA5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .patientlist-container .add-patient-button:hover {
    background-color: #009688;
  }
  
  .patientlist-container .patientlist-list {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .patientlist-container .patient-card {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .patientlist-container .patient-card:hover {
    background-color: #f0f0f0;
  }
  
  .patientlist-container .patient-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .patientlist-container .patient-details {
    display: flex;
    flex-direction: column;
  }
  
  .patientlist-container .patient-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .patientlist-container .patient-phone {
    font-size: 14px;
    color: #777;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .close-popup {
    float: right;
    cursor: pointer;
    color: red;
    font-size: 20px;
  }

.patientlist-container .popup-error {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.patientlist-container .popup-content-error {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.patientlist-container .close-popup-error {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}