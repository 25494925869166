.mainview-container-mainview {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Faz o container ocupar toda a altura da tela */
}

/* Topbar fixo no topo */
.topbar-mainview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px; /* Reduzi o padding para ajustar o espaço interno */
    background-color: #00BFA5; /* Verde principal */
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px; /* Altura ajustada para um tamanho mais compacto */
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona sombra para o topbar */
    box-sizing: border-box; /* Garante que padding e border não aumentem a largura do topbar */
}

.topbar-logo-mainview {
    font-size: 20px; /* Reduzi o tamanho da fonte do logo */
    font-weight: bold;
    white-space: nowrap; /* Impede quebra de linha no logo */
    cursor: default
}

.topbar-right-mainview {
    display: flex;
    align-items: center;
    white-space: nowrap; /* Impede quebra de linha nos botões */
}

.topbar-button-mainview, .topbar-logout-mainview {
    background-color: transparent;
    border: none;
    color: white;
    margin-left: 10px; /* Reduzi o espaçamento entre os botões */
    cursor: pointer;
    font-size: 16px;
}

.topbar-button-mainview:hover, .topbar-logout-mainview:hover {
    text-decoration: underline;
}

/* Ajusta o conteúdo abaixo do topbar fixo */
.mainview-content-mainview {
    flex-grow: 1;
    background-color: #F5F5F5;
    margin-top: 50px; /* Margem para compensar a altura do topbar */
    height: calc(100vh - 50px); /* Altura do conteúdo, ocupando o restante da tela */
    overflow-y: auto; /* Permite rolagem no conteúdo */
}

/* Esconde a barra de rolagem, mantendo a rolagem funcional */
.mainview-content-mainview::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Esconde a barra de rolagem no Chrome/Safari */
}

.mainview-content-mainview {
    -ms-overflow-style: none; /* Esconde a barra de rolagem no IE/Edge */
    scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */
}

.mainview-container-mainview .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.mainview-container-mainview .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.mainview-container-mainview .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.mainview-container-mainview .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.mainview-container-mainview .modal-buttons button:first-child {
    background-color: gray;
    color: white;
}

.mainview-container-mainview .modal-buttons button:last-child {
    background-color: #00BFA5;
    color: white;
}