.radio-group {
    margin-bottom: 20px;
}

.radio-options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.radio-options label {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.radio-options input {
    cursor: pointer;
}
