/* FeetInfo.css */
.feet-info-container {
    padding: 20px;
    margin: 0 auto;
    width: 400px;
}

.feet-info-container .input-group {
    margin-bottom: 15px;
}

.feet-info-container .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.feet-info-container .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.feet-info-container .radio-group {
    margin-bottom: 15px;
}

/* Flexbox para radio buttons em coluna */
.feet-info-container .radio-group.vertical {
    display: flex;
    flex-direction: column; /* Coloca os radio buttons em uma coluna */
    margin-top: 15px;
}

.feet-info-container .radio-group.vertical label {
    margin-bottom: 5px; /* Espaço entre os radio buttons */
}

/* Opcional: você pode manter o estilo horizontal, caso deseje usá-lo em outro lugar */
.feet-info-container .radio-group.horizontal {
    display: flex;
    gap: 10px; /* Adiciona espaço entre as opções de rádio se você usar horizontal */
}

.feet-info-container .update-button {
    background-color: #00BFA5;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.feet-info-container .update-button:hover {
    background-color: #009e8a;
}
.feet-info-container .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Fica acima de outros elementos */
}

.feet-info-container .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.feet-info-container .close-popup {
    background-color: #00BFA5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}